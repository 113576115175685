/* React Tabs */
.react-tabs {
  .react-tabs__tab-list {
    border-bottom-color: var(--xa-secondary);
    @include flex(flex);
    @include flex-align-items(center);
    @include flex-direction(row);
    margin: 0;
    width: 100%;

    .react-tabs__tab {
      border: 0 none;
      color: var(--xa-text);
      display: inline-block;
      padding: 0.8rem 1.6rem;
      text-align: center;
      width: 100%;

      &:after {
        display: none;
      }

      &.react-tabs__tab--selected {
        background: var(--xa-info);
        @include border-radius(0);
        color: var(--xa-white);

        .dark & {
          color: var(--xa-black);
        }
      }
    }
  }

  .react-tabs__tab-panel {
    padding: 0.8rem;

    .xa-attributes-list {
      margin: -0.8rem;
    }
  }
}
