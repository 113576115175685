/* Map */
#map {
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);

  .esri-view-surface,
  .esri-view-surface--inset-outline {
    &:after {
      outline: none;
    }
  }
}
