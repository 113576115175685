/* Radio */
.xa-radio-input {
  @include appearance(none);
  background: var(--xa-white);
  border: 0.1rem solid var(--xa-secondary);
  @include border-radius(100%);
  cursor: pointer;
  height: 1.6rem;
  width: 1.6rem;

  &:focus {
    outline: none;
  }

  .dark & {
    background: var(--xa-black);
    border-color: var(--xa-text);
  }

  &:checked {
    background: var(--xa-text);
    border-color: var(--xa-text);
    @include box-shadow(0 0 0 0.3rem var(--xa-white) inset);

    .dark & {
      background: var(--xa-text);
      @include box-shadow(0 0 0 0.3rem var(--xa-black) inset);
    }
  }
}

.xa-radio-label {
  color: var(--xa-text);
  cursor: pointer;
  margin-left: 0.8rem;
}
