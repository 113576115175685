/* ESRI Custom Style */
.esri-view {
  .esri-ui-inner-container {
    inset: 0.8rem !important;
  }

  .esri-ui-corner {
    .esri-widget {
      background-color: var(--xa-white);
      color: var(--xa-text);
      font-family: $xa-font-family;
      line-height: $xa-line-height;
      --esri-widget-padding-v: 0.8rem;
      --esri-widget-padding-h: 0.8rem;

      .dark & {
        background-color: var(--xa-black);
      }
    }

    .esri-component {
      background: var(--xa-white);
      @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.75));
      @include border-radius(0.4rem);

      .dark & {
        background: var(--xa-black);
      }
    }

    .esri-button,
    .esri-elevation-profile__header button {
      background-color: var(--xa-text);
      border: 0 none;
      @include border-radius(0.4rem);
      color: var(--xa-white);
      line-height: 2rem;
      min-height: 3.6rem;
      padding: 0.8rem;

      .dark & {
        color: var(--xa-black);
      }

      &:hover {
        background-color: var(--xa-text);
        border: 0 none;
        color: var(--xa-white);

        .dark & {
          color: var(--xa-black);
        }
      }
    }

    /* Distance Measurement */
    .esri-distance-measurement-2d {
      .esri-distance-measurement-2d__container {
        padding: 0.8rem 0;
      }

      .esri-distance-measurement-2d__settings {
        padding: 0 0.8rem;

        .esri-distance-measurement-2d__units-select {
          @include appearance(none);
          background-image: linear-gradient(45deg, transparent 50%, var(--xa-secondary) 50%),
                            linear-gradient(135deg, var(--xa-secondary) 50%, transparent 50%);
          background-position: calc(100% - 1.6rem) calc(1em + 0.2rem),
                               calc(100% - 1.1rem) calc(1em + 0.2rem);
          background-size: 0.5rem 0.5rem, 0.5rem 0.5rem;
          background-repeat: no-repeat;
          background-color: var(--xa-white);
          border: 0.1rem solid var(--xa-secondary);
          @include border-radius(0.4rem);
          @include box-shadow(none);
          color: var(--xa-text);
          display: block;
          font-family: $xa-font-family;
          font-size: $xa-font-size;
          height: 3.6rem;
          line-height: 1.8rem;
          margin: 0;
          outline: 0;
          padding: 0.8rem 3.2rem 0.8rem 0.8rem;
          @include transition(border-color 0.4s ease-in-out);
          width: 100%;

          &:focus {
            border-color: var(--xa-primary);
          }

          &.invalid {
            border-color: var(--xa-danger);
          }

          .dark & {
            background-image: linear-gradient(45deg, transparent 50%, var(--xa-text) 50%),
                              linear-gradient(135deg, var(--xa-text) 50%, transparent 50%);
            background-color: var(--xa-secondary);
          }

          &[disabled],
          &.disabled,
          &:disabled {
            background: var(--xa-text);

            .dark & {
              background: rgba(var(--xa-text-rgb), 0.5);
            }
          }
        }
      }

      .esri-distance-measurement-2d__measurement {
        background-color: rgba(var(--xa-secondary-rgb), 0.5);
        margin: 0.8rem 0;
        padding: 0.8rem;

        .esri-distance-measurement-2d__measurement-item {
          padding-bottom: 0;
        }

        .esri-distance-measurement-2d__measurement-item--disabled {
          color: var(--xa-secondary);
        }

        .esri-distance-measurement-2d__measurement-item-title {
          padding-bottom: 0.4rem;
        }

        .esri-distance-measurement-2d__measurement-item-value {
          font-weight: 700;
        }
      }

      .esri-distance-measurement-2d__actions {
        padding: 0 0.8rem;
      }

      .esri-distance-measurement-2d__hint {
        padding: 0 0.8rem;
      }

      .esri-distance-measurement-2d__hint-text {
        margin: 0;
      }
    }

    /* Zoom Buttons */
    .esri-zoom {
      background: transparent;
      @include border-radius(0);
      @include box-shadow(none);

      .dark & {
        background: transparent;
      }

      .esri-widget--button {
        background-color: var(--xa-text);
        border: 0 none;
        color: var(--xa-white);
        font-size: $xa-font-size;
        height: 2.4rem;
        margin: 0;
        width: 2.4rem;

        .dark & {
          background-color: var(--xa-black);
        }

        &:first-child {
          margin-bottom: 0.1rem;
        }

        &:last-child {
          border-top: 0 none;
        }

        &.esri-disabled {
          cursor: default;
          @include opacity(0.75);

          .esri-icon {
            color: rgba(var(--xa-secondary-rgb), 0.5);
          }
        }
      }

      &.esri-zoom--horizontal {
        .esri-widget--button {
          &:first-child {
            border-left: 0 none;
          }

          &:last-child {
            border-top: none;
          }
        }
      }
    }

    /* Scale Bar */
    .esri-scale-bar {
      background: transparent;
      @include border-radius(0);
      @include box-shadow(none);

      .dark & {
        background: transparent;
      }

      .esri-scale-bar__ruler {
        background-color: var(--xa-white);
        @include box-shadow(none);
      }

      .esri-scale-bar__ruler-block {
        background-color: var(--xa-text);
      }

      .esri-scale-bar__line {
        background-color: rgba(var(--xa-white-rgb), 0.5);

        .dark & {
          background-color: rgba(var(--xa-black-rgb), 0.15);
        }
      }

      .esri-scale-bar__line--top {
        border-bottom: 0.2rem solid var(--xa-text);

        .dark & {
          border-color: var(--xa-black);
        }
      }

      .esri-scale-bar__line--bottom {
        border-top: 0.2rem solid var(--xa-text);

        .dark & {
          border-color: var(--xa-black);
        }
      }

      .esri-scale-bar__line--top:before,
      .esri-scale-bar__line--top:after,
      .esri-scale-bar__line--bottom:before,
      .esri-scale-bar__line--bottom:after {
        background-color: var(--xa-text);
        border-right: 0.2rem solid var(--xa-text);
      }

      .esri-scale-bar__label {
        color: var(--xa-text);

        .dark & {
          color: var(--xa-black);
        }
      }
    }
  }
}
