/* Grid */
.xa-grid {
  background: var(--xa-white);
  @include border-radius(0.8rem);
  @include box-shadow(0 0 1rem 0.2rem rgba(var(--xa-text-rgb), 0.2));
  height: 100%;
  min-height: 30rem;

  &.ag-theme-alpine,
  &.ag-theme-alpine-dark {
    --ag-font-family: $xa-font-family;
  }

  .ag-root-wrapper {
    border: 0 none;
    @include border-radius(0.8rem);
    height: 100%;
    min-height: 30rem;

    .ag-root-wrapper-body {
      height: 100%;
      min-height: 30rem;

      .ag-root.ag-layout-normal {
        height: auto;
      }
    }
  }

  .xa-grid-btns {
    background: #FFF;
    border-bottom: 0.1rem solid rgba(var(--xa-secondary-rgb), 0.5);
    @include border-radius(0.8rem 0.8rem 0 0);
    @include flex(flex);
    @include flex-align-items(center);
    @include flex-justify(flex-end);
    padding: 0.8rem;

    .dark & {
      background: var(--xa-secondary);
    }

    .xa-grid-dropdown-btn {
      background-color: rgba(var(--xa-secondary-rgb), 0.5);
      @include border-radius(0.4rem);
      color: var(--xa-text);
      cursor: pointer;
      @include flex(flex);
      @include flex-align-items(center);
      @include flex-justify(center);
      font-family: $xa-font-family;
      font-size: 1.4rem;
      font-weight: 400;
      gap: 0.8rem;
      line-height: 1.5;
      margin-top: 0;
      padding: 0.4rem 0.8rem;

      .dark & {
        background-color: rgba(var(--xa-text-rgb), 0.25);
      }
    }

    + div {
      .ag-root-wrapper {
        @include border-radius(0 0 0.8rem 0.8rem);
      }
    }
  }

  .ag-header {
    background-color: rgba(var(--xa-secondary-rgb), 0.15);
    border-bottom-color: rgba(var(--xa-secondary-rgb), 0.5);
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .ag-body-viewport {
    overflow: overlay;

    @-moz-document url-prefix() {
      overflow: auto;
    }

    .ag-cell-focus {
      border-color: transparent !important;
    }
  }

  .xa-grid-admin-cell,
  .xa-grid-status-cell,
  .xa-grid-actions-cell {
    @include flex(flex);
    @include flex-align-items(center);
    height: 100%;
  }

  .xa-grid-status {
    border-width: 0.1rem;
    border-style: solid;
    @include border-radius(0.4rem);
    font-family: $xa-font-family;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.25;
    padding: 0.4rem 0.8rem;
    width: 90px;
    text-align: center;

    &.active {
      border-color: var(--xa-success);
      color: var(--xa-success);
    }

    &.inactive {
      border-color: var(--xa-secondary);
      color: var(--xa-secondary);
    }
  }

  .xa-grid-more-btn {
    background: rgba(var(--xa-secondary-rgb), 0.75);
    @include border-radius(0.4rem);
    color: var(--xa-text);
    cursor: pointer;
    @include flex(flex);
    @include flex-align-items(center);
    height: 2.4rem;
    line-height: 1;
    padding: 0.4rem;
    width: 2.4rem;

    .dark & {
      background-color: rgba(var(--xa-text-rgb), 0.25);
    }
  }

  .xa-grid-btns-cell {
    @include flex(flex);
    @include flex-align-items(center);
    gap: 0.8rem;
    height: 100%;

    .xa-button {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.25;
      margin-top: 0;
      padding: 0.4rem 0.8rem;
      @include transition(0.4s ease-in-out);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .xa-grid-empty {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0.5;

    .image {
      background-image: url(../../assets/images/xa-empty.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      height: 20rem;
      width: 27rem;
    }

    .text {
      color: var(--lp-secondary);
      font-size: 2rem;
    }
  }

  .ag-layout-auto-height,
  .ag-layout-print {
    .ag-center-cols-clipper,
    .ag-center-cols-container {
      min-height: 100%;
    }
  }

  .ag-overlay {
    height: calc(100% - 4.9rem);
    top: 4.9rem;
  }

  .ag-paging-panel {
    border-top-color: rgba(var(--xa-secondary-rgb), 0.5);
  }
}
