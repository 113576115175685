@import 'styles/mixins';

/* Layers */
.xa-layers-container {
  @include flex(flex);
  @include flex-direction(column);
  padding: 0.8rem 1.6rem;
}

.xa-layer-item {
  @include flex(flex);
  @include flex-align-items(center);
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }
}
