/* RC Dropdown */
.rc-menu-item {
  color: var(--xa-text);
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: var(--xa-white);
  }
}

.rc-dropdown {
  display: block;
  font-size: 12px;
  font-weight: normal;
  left: -9999px;
  line-height: 1.5;
  position: absolute;
  top: -9999px;
  z-index: 1070;

  &.rc-dropdown-hidden {
    display: none;
  }

  .rc-menu {
    background-color: #fff;
    background-clip: padding-box;
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 0 1rem 0.2rem rgba(var(--xa-text-rgb), 0.2);
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: relative;
    text-align: left;

    .dark & {
      background-color: #5D5E65;
    }

    .rc-menu-item {
      &:hover,
      &-active,
      &-selected {
        background-color: rgba(var(--xa-secondary-rgb), 0.5);
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .rc-menu-item-divider {
      display: none;
    }

    .rc-menu-item-disabled {
      color: #ccc;
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        background-color: #fff;
        color: #ccc;
        cursor: not-allowed;
      }
    }
  }
}

.rc-dropdown-slide-up-enter,
.rc-dropdown-slide-up-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
  display: block !important;
  opacity: 0;
  transform-origin: 0 0;
}

.rc-dropdown-slide-up-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
  display: block !important;
  opacity: 1;
  transform-origin: 0 0;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpIn;
  animation-play-state: running;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownIn;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpOut;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownOut;
  animation-play-state: running;
}

@keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
}

@keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
}
