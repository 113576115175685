@import 'styles/mixins';

/* Admin Page */
.xa-admin-page {
  background: #FFF;

  .dark & {
    background: var(--xa-secondary);
  }

  .xa-admin-content {
    @include flex(flex);
    @include flex-align-content(flex-start);
    @include flex-align-items(flex-start);
    @include flex-direction(row);
    @include flex-justify(flex-start);
    @include flex-wrap(wrap);
    gap: 2.4rem;
    height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
    height: var(--vh, 1vh);
    margin-left: 4.8rem;
    overflow: auto;
    padding: 2.4rem;
    width: calc(100vw - 4.8rem);

    @include breakpoint('phone-md') {
      margin-left: 0;
      width: 100vw;
    }
  
    .xa-admin-column {
      &.tenants {
        width: calc(40% - 1.2rem);

        @include breakpoint('tablet') {
          width: 100%;
        }
      }

      &.new-users {
        width: calc(60% - 1.2rem);

        @include breakpoint('tablet') {
          width: 100%;
        }
      }

      &.registered-users {
        width: 100%;
      }
    }

    .xa-section-title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1.6rem;
    }
  }
}
