/* Form Fields */
.xa-form-field {
  + .xa-form-field {
    margin-top: 2.4rem;
  }

  .relative {
    position: relative;
  }

  &.inline {
    @include flex(flex);
    @include flex-align-items(center);
    @include flex-direction(row);
    gap: 0.8rem;

    .custom_select {
      width: 100%;
    }

    .rc-input-number {
      margin-left: 0;
    }
  }

  &.small {
    .custom_select {
      margin: 0 auto;
      max-width: 9rem;
    }
  }

  &.error {
    color: var(--xa-danger);
    font-size: 1.2rem;
  }
}

/* Inputs */
body {
  input {
    &[type="text"],
    &[type="password"],
    &[type="datetime"],
    &[type="datetime-local"],
    &[type="date"],
    &[type="month"],
    &[type="time"],
    &[type="week"],
    &[type="number"],
    &[type="email"],
    &[type="url"],
    &[type="search"],
    &[type="tel"],
    &[type="color"],
    &[type="file"] {
      background: var(--xa-white);
      border: 0.1rem solid var(--xa-secondary);
      @include border-radius(0.4rem);
      @include box-shadow(none);
      color: var(--xa-text);
      display: block;
      font-family: $xa-font-family;
      font-size: $xa-font-size;
      height: 3.6rem;
      line-height: 1.8rem;
      margin: 0;
      outline: 0;
      padding: 0.8rem;
      @include transition(border-color 0.4s ease-in-out);
      width: 100%;

      &:focus {
        border-color: var(--xa-primary);
      }

      &.invalid {
        border-color: var(--xa-danger);
      }

      .dark & {
        background: var(--xa-secondary);
      }
    }

    &[type="password"] {
      padding-right: 3rem;

      &::-ms-reveal {
        display: none;
      }
    }

    &[disabled],
    &.disabled,
    &:disabled {
      background: var(--xa-text-alt);

      .dark & {
        background: rgba(var(--xa-text-rgb), 0.5);
      }
    }

    &[type="number"] {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        height: auto;
      }
    }

    &[type="search"] {
      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration {
        -webkit-appearance: none;
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border-color: var(--xa-primary);
      -webkit-text-fill-color: var(--xa-text);
      -webkit-box-shadow: 0 0 0 100rem var(--xa-white) inset;
      @include transition(background-color 5000s ease-in-out 0s);

      .dark & {
        -webkit-box-shadow: 0 0 0 100rem var(--xa-secondary) inset;
      }
    }
  }

  textarea {
    background: var(--xa-white);
    border: 0.1rem solid var(--xa-secondary);
    @include border-radius(0.4rem);
    @include box-shadow(none);
    color: var(--xa-text);
    display: block;
    font-family: $xa-font-family;
    font-size: $xa-font-size;
    height: auto;
    line-height: 1.8rem;
    margin: 0;
    max-width: 100%;
    min-height: 7.2rem;
    outline: 0;
    overflow: auto;
    padding: 0.8rem;
    @include transition(border-color 0.4s ease-in-out);
    width: 100%;

    &:focus {
      border-color: var(--xa-primary);
    }

    &.invalid {
      border-color: var(--xa-danger);
    }

    &[disabled],
    &.disabled,
    &:disabled {
      background: var(--xa-text-alt);

      .dark & {
        background: rgba(var(--xa-text-rgb), 0.5);
      }
    }

    .dark & {
      background: var(--xa-secondary);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border-color: var(--xa-primary);
      -webkit-text-fill-color: var(--xa-text);
      -webkit-box-shadow: 0 0 0 100rem var(--xa-white) inset;
      @include transition(background-color 5000s ease-in-out 0s);

      .dark & {
        -webkit-box-shadow: 0 0 0 100rem var(--xa-secondary) inset;
      }
    }
  }

  select {
    @include appearance(none);
    background-image: linear-gradient(45deg, transparent 50%, var(--xa-secondary) 50%),
                      linear-gradient(135deg, var(--xa-secondary) 50%, transparent 50%);
    background-position: calc(100% - 1.6rem) calc(1em + 0.2rem),
                         calc(100% - 1.1rem) calc(1em + 0.2rem);
    background-size: 0.5rem 0.5rem, 0.5rem 0.5rem;
    background-repeat: no-repeat;
    background-color: var(--xa-white);
    border: 0.1rem solid var(--xa-secondary);
    @include border-radius(0.4rem);
    @include box-shadow(none);
    color: var(--xa-text);
    display: block;
    font-family: $xa-font-family;
    font-size: $xa-font-size;
    height: 3.6rem;
    line-height: 1.8rem;
    margin: 0;
    outline: 0;
    padding: 0.8rem 3.2rem 0.8rem 0.8rem;
    @include transition(border-color 0.4s ease-in-out);
    width: 100%;

    &:focus {
      border-color: var(--xa-primary);
    }

    &.invalid {
      border-color: var(--xa-danger);
    }

    .dark & {
      background-image: linear-gradient(45deg, transparent 50%, var(--xa-text) 50%),
                        linear-gradient(135deg, var(--xa-text) 50%, transparent 50%);
      background-color: var(--xa-secondary);
    }

    &[disabled],
    &.disabled,
    &:disabled {
      background: var(--xa-text-alt);

      .dark & {
        background: rgba(var(--xa-text-rgb), 0.5);
      }
    }
  }

  label {
    color: var(--xa-text);
    display: block;
    font-family: $xa-font-family;
    font-size: $xa-font-size;


    &.required {
      &:after {
        color: var(--xa-danger);
        content: '*';
        margin-left: 0.4rem;
      }
    }
  }

  legend {
    border: 0;
    display: block;
    margin-bottom: 0;
    padding: 0;
  }

  optgroup {
    font-weight: 700;
  }

  fieldset {
    border: 0.1rem solid var(--xa-secondary);
    @include border-radius(0.4rem);
    margin: 0;
    padding: 0.8rem;

    legend {
      font-weight: 700;
      padding: 0 0.4rem;
    }
  }
}
