/* Switch */
.custom-switch {
  background-color: var(--xa-white);
  border: 0.1rem solid var(--xa-secondary);
  @include border-radius(2.4rem);
  display: inline-block;
  padding: 0.2rem;
  width: auto;

  .dark & {
    background-color: var(--xa-secondary);
  }

  label {
    @include border-radius(2.4rem);
    color: var(--xa-text);
    display: inline-block;
    font-size: $xa-font-size;
    padding: 0.4rem 2.4rem;
    text-align: center;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 0.1rem;
    overflow: hidden;
    position: absolute;
    width: 0.1rem;

    &:checked {
      + label {
        background-color: var(--xa-text);
        color: var(--xa-white);

        .dark & {
          background-color: var(--xa-primary);
          color: var(--xa-black);
        }
      }
    }
  }
}
