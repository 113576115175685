@import 'styles/mixins';

/* Loader */
.xa-loader-container {
  background: rgba(var(--xa-secondary-rgb), 0.75);
  height: calc(100% - 3.7rem);
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-justify(center);
  left: 0;
  position: absolute;
  top: 3.7rem;
  width: 100%;
  z-index: 999;
}

.xa-loader {
  animation: spin 1s linear infinite;
  color: var(--xa-secondary);
  fill: var(--xa-text);
  height: 3.2rem;
  width: 3.2rem;

  .dark & {
    fill: var(--xa-primary);
  }
}
