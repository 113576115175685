/*====================
RESET STYLE
====================*/
*,
*:before,
*:after {
  @include box-sizing(border-box);
}

*:focus {
  outline: 0 none;
}

html,
body {
  -moz-osx-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  min-height: 100%;
  overscroll-behavior-y: contain;
  padding: 0;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 10px;
}

body {
  background: var(--xa-white);
  color: var(--xa-text);
  font-family: $xa-font-family;
  font-size: $xa-font-size;
  line-height: $xa-line-height;
  overflow-x: hidden;
}

/* Scrollbars */
::-webkit-scrollbar {
  -webkit-appearance: none;
  background: transparent;
  height: 0.6rem;
  width: 0.6rem;
}

::-webkit-scrollbar-track {
  background-color: #F1F5F9;
  @include border-radius(0.4rem);

  .dark & {
    background-color: rgba(100, 116, 139, 0.16);
  }
}

::-webkit-scrollbar-thumb {
  background-color: #CBD5E1;
  @include border-radius(0.4rem);

  .dark & {
    background-color: rgba(100, 116, 139, 0.5);
  }
}

@-moz-document url-prefix() {
  * {
    scrollbar-color: #CBD5E1 #F1F5F9;
    scrollbar-width: thin;
  }
}

/* Links */
a {
  cursor: pointer;
  text-decoration: none;

  &:visited,
  &:active {
    color: inherit;

    .dark & {
      color: inherit;
    }
  }
}

/* Horizontal line */
hr {
  background-color: var(--xa-secondary);
  border: none;
  height: 0.1rem;
  overflow: initial;

  .dark & {
    background-color: rgba(var(--xa-text-rgb), 0.5);
  }
}

/* Text selection */
::selection {
  background: var(--xa-text);
  color: var(--xa-white);
  text-shadow: none;

  .dark & {
    background: var(--xa-primary);
    color: var(--xa-text);
  }
}

/* Typography */
small {
  font-size: 80%;
}

b,
strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

pre {
  overflow: overlay;

  @-moz-document url-prefix() {
    overflow-y: auto;
  }

  > code {
    display: block;
    padding: 0.8rem 1.6rem;
    white-space: pre;
  }
}

sup,
sub {
  font-size: 60%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.75em;
}

sub {
  bottom: -0.25em;
}
