@import "styles/font";
@import "styles/mixins";

/* Toggle Switch */
.xa-toggle-switch {
  display: inline-block;
  line-height: 2.4rem;
  position: relative;

  input {
    height: 2rem;
    @include opacity(0);
    position: absolute;
    right: 0;
    top: 0;
    width: 3.6rem;
    z-index: 0;
  }

  label {
    cursor: pointer;
    display: block;
    padding: 0 4.4rem 0 0;

    &:before {
      background-color: var(--xa-secondary);
      @include border-radius(1.4rem);
      content: "";
      height: 1.4rem;
      position: absolute;
      right: 0;
      top: 0.5rem;
      @include transition(background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1));
      width: 3.6rem;
      z-index: 1;
    }

    &:after {
      background-color: var(--xa-white);
      @include border-radius(1.4rem);
      @include box-shadow(0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12));
      content: "";
      height: 2rem;
      position: absolute;
      right: 1.6rem;
      top: 0.2rem;
      @include transition((background-color 0.28s cubic-bezier(0.4, 0, 0.2, 1), right 0.28s cubic-bezier(0.4, 0, 0.2, 1)));
      width: 2rem;
      z-index: 2;
    }
  }

  input:checked + label {
    &:before {
      background-color: rgba(var(--xa-text-rgb), 0.4);

      .dark & {
        background-color: rgba(var(--xa-primary-rgb), 0.4);
      }
    }

    &:after {
      background-color: var(--xa-text);
      right: 0;

      .dark & {
        background-color: var(--xa-primary);
      }
    }
  }
}
