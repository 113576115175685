@import 'styles/mixins';

/* Auth Pages */
.xa-auth-page {
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);

  @include breakpoint('tablet') {
    @include flex-align-items(flex-start);
  }
}

.xa-auth-page-content {
  background: var(--xa-white);
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(column);
  @include flex-justify(space-between);
  height: 100%;
  max-width: calc(100% - 96rem);
  position: relative;
  width: 100%;

  .dark & {
    background: var(--xa-secondary);
  }

  @include breakpoint('laptop-lg') {
    max-width: calc(100% - 76.8rem);
  }

  @include breakpoint('laptop-sm') {
    max-width: calc(100% - 60rem);
  }

  @include breakpoint('tablet-landscape') {
    @include flex-justify(center);
  }

  @include breakpoint('tablet-portrait') {
    @include flex-justify(center);
  }

  @include breakpoint('tablet') {
    background-image: url(../../assets/images/xa-login-road.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    overflow-y: auto;
    padding: 1.6rem 1.6rem 0;

    &:after {
      background: rgba(var(--xa-black-rgb), 0.5);
      content: '';
      height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
      height: var(--vh, 1vh);
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }
  }
}

.xa-auth-page-theme-toggle {
  background-image: url(../../assets/images/theme-toggle-light.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 3.5rem;
  position: absolute;
  right: -12.5rem;
  top: 1.6rem;
  width: 5.8rem;
  z-index: 99;

  .dark & {
    background-image: url(../../assets/images/theme-toggle-dark.webp);
  }

  @include breakpoint('tablet') {
    right: 1.6rem;
  }
}

.xa-auth-page-content-wrapper {
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(column);
  @include flex-justify(center);
  height: 100%;
  max-width: 50rem;
  width: 100%;

  .register-page & {
    max-width: 70rem;

    @include breakpoint('laptop-lg') {
      max-width: 60rem;
    }

    @include breakpoint('laptop-sm') {
      max-width: 60rem;
    }
  }

  @include breakpoint('tablet') {
    height: auto;
  }
}

.xa-auth-page-login-box {
  background: var(--xa-white);
  border: 0.1rem solid var(--xa-secondary);
  @include border-radius(0.8rem);
  padding: 4rem 8rem;
  width: 100%;

  .dark & {
    background: var(--xa-black);
    border-color: rgba(var(--xa-text-rgb), 0.5);
  }

  .register-page & {
    @include breakpoint('laptop-lg') {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  @include breakpoint('tablet') {
    padding: 2rem 4rem;
    position: relative;
    z-index: 10;
  }
}

.xa-auth-page-title {
  text-align: center;

  h1 {
    color: var(--xa-text);
    font-size: 3.6rem;
    font-weight: 900;

    @include breakpoint('phone-md') {
      font-size: 2.8rem;
    }
  }
}

.xa-auth-page-logo {
  img {
    display: block;
    height: 7.5rem;
    margin: auto;
    @include opacity(0.25);

    @include breakpoint('phone-md') {
      height: 5rem;
    }
  }
}

.xa-auth-page-copyright {
  color: rgba(var(--xa-black-rgb), 0.5);
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0.8rem;
  text-align: center;

  .dark & {
    color: rgba(var(--xa-text-rgb), 0.5);
  }

  @include breakpoint('tablet') {
    color: var(--xa-white);
    position: relative;
    z-index: 10;
  }
}

.xa-auth-page-separator {
  filter: drop-shadow(1rem 0 0.5rem rgba(var(--xa-black-rgb), 0.25));
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  left: 100%;
  position: absolute;
  top: 0;

  @include breakpoint('tablet') {
    display: none;
  }
}

.xa-auth-page-polygon {
  background: var(--xa-white);
  clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
  display: block;
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  width: 15rem;

  .dark & {
    background: var(--xa-secondary);
  }
}

.xa-auth-page-image {
  background-image: url(../../assets/images/xa-login-road.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  max-width: 96rem;
  width: 100%;

  @include breakpoint('laptop-lg') {
    max-width: 76.8rem;
  }

  @include breakpoint('laptop-sm') {
    max-width: 60rem;
  }

  @include breakpoint('tablet') {
    display: none;
  }
}

.xa-auth-page-form-row {
  @include flex(flex);
  @include flex-direction(row);
  gap: 1.6rem;
  width: 100%;

  + .xa-auth-page-form-row {
    margin-top: 2.4rem;

    @include breakpoint('phone-md') {
      margin-top: 1.6rem;
    }
  }

  @include breakpoint('phone-md') {
    @include flex-direction(column);
  }

  .xa-form-field {
    margin-top: 0 !important;
    width: 100%;
  }
}
