@import 'styles/mixins';

/* Charts */
.xa-chart {
  width: 900px;
  max-width: 122%;
  height: 60vh;
  margin-top: 1.6rem;
  // margin: auto;
}

.xa-chart-text{
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.xa-chip {
  background-color: var(--xa-primary);
  @include border-radius(0.4rem);
  color: var(--xa-text);
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  @include flex-justify(space-between);
  margin-right: 0.8rem;
  padding: 0.2rem 0.8rem;
  width: auto;

  .dark & {
    color: var(--xa-black);
  }
}

.xa-chip-reset {
  cursor: pointer;
  @include flex(flex);
  margin-left: 0.8rem;
}

.with-chart {
  .xa-form-field {
    + .xa-form-field {
      margin-top: 0.8rem;
    }

    &.chart-selected {
      @include flex(flex);
      @include flex-direction(row);
      @include flex-justify(center);
      @include flex-wrap(wrap);
      margin-top: 0.8rem;
      width: auto;
    }
  }
}
