/* RC Tree */
.rc-tree {
  .rc-tree-node-selected {
    background-color: var(--xa-complementary);
    @include border-radius(0.4rem);
    @include box-shadow(none);
    @include opacity(1);

    .rc-tree-title {
      .dark & {
        color: var(--xa-white);
      }
    }
  }
}
