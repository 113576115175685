/* RC Input Number */
.react-modal {
  .rc-input-number {
    border: 0.1rem solid var(--xa-secondary);
    font-size: $xa-font-size;
    height: 3.6rem;
    line-height: 3.6rem;
    margin-left: 0.8rem;
    max-width: 10rem;

    &.rc-input-number-focused {
      border-color: var(--xa-primary);
      @include box-shadow(none);
    }

    .rc-input-number-handler-wrap {
      border-left: 0.1rem solid var(--xa-secondary);
      width: 2.4rem;
    }

    .rc-input-number-handler-up {
      border-bottom: 0.1rem solid var(--xa-secondary);
    }

    .rc-input-number-handler {
      height: 1.7rem;
      line-height: 1.6rem;
    }

    .rc-input-number-handler-down-disabled,
    .rc-input-number-handler-up-disabled {
      background-color: rgba(var(--xa-secondary-rgb), 0.5);
      @include opacity(1);
    }

    .rc-input-number-input {
      background-color: var(--xa-white);
      @include border-radius(0.4rem 0 0 0.4rem);
      color: var(--xa-text);
      font-size: 1.4rem;
      position: relative;
      top: -0.1rem;

      .dark & {
        background-color: var(--xa-secondary);
      }
    }
  }
}
