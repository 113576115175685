@import 'styles/mixins';

/* Login Form */
.xa-auth-page-form-wrapper {
  margin: 2rem auto;

  @include breakpoint('phone-md') {
    margin: 4rem auto;
  }
}

.xa-auth-page-form-errors {
  background: var(--xa-d-text);
  @include border-radius(0.4rem);
  color: var(--xa-danger);
  margin-top: 1.6rem;
  max-height: 12.8rem;
  padding: 0.8rem;
  overflow-y: scroll;
  word-break: break-word;

  .dark & {
    background: var(--xa-secondary);
  }

  &:empty {
    display: none;
  }
}

.xa-auth-page-form-error {
  color: var(--xa-danger);
  font-size: 1.2rem;
}

.xa-auth-page-form-password-view {
  @include border-radius(0.4rem);
  color: var(--xa-text);
  cursor: pointer;
  font-size: 1.8rem;
  line-height: 2.8rem;
  position: absolute;
  right: 0.8rem;
  top: 0.7rem;
}

.xa-auth-page-form-link {
  margin-top: 2.4rem;
  text-align: right;

  a {
    color: var(--xa-text);

    .dark & {
      color: var(--xa-primary);
    }
  }
}

