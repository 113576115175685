@import 'styles/mixins';

/* Search Container */
.xa-search-container {
  background: var(--xa-white);
  @include border-radius(0.4rem);
  @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.5));
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  @include flex-justify(space-between);
  left: 50%;
  max-width: 35rem;
  @include opacity(0.75);
  padding: 0.4rem 0.8rem;
  position: fixed;
  top: 0.8rem;
  @include transition(all 0.3s ease-in-out);
  @include translate(-50%, 0);
  width: 100%;
  z-index: 15;

  @include breakpoint('phone-md') {
    max-width: 25rem;
  }

  .dark & {
    background: var(--xa-primary);
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.5));
  }

  &:hover {
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.75));
    @include opacity(1);

    .dark & {
      @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.75));
    }
  }

  > input {
    background: transparent !important;
    border: 0 none !important;
    @include border-radius(0 !important);
    @include box-shadow(none !important);
    height: 2.8rem !important;
    margin: 0 0.8rem !important;
    padding: 0 0 0 0.4rem !important;

    @include placeholder {
      .dark & {
        color: var(--xa-black);
      }
    }

    .dark & {
      background: var(--xa-primary);
      color: var(--xa-black);
    }
  }

  &.focused {
    @include box-shadow(0 0 1rem 0 var(--xa-text));
    max-width: 50rem;
    @include opacity(1);

    .dark & {
      @include box-shadow(0 0 1rem 0 var(--xa-text-alt));
    }

    @include breakpoint('phone-md') {
      max-width: 35rem;
    }
  }
}

.xa-search-icon {
  color: var(--xa-secondary);
  font-size: 2rem;
  line-height: 2.8rem;
  min-width: 2rem;
}

.xa-search-results-category {
  background: var(--xa-secondary);
  @include border-radius(0.4rem);
  color: var(--xa-text);
  margin-right: 0.8rem;
  padding: 0 0.4rem;
  white-space: nowrap;

  .dark & {
    color: var(--xa-white);
  }
}

.xa-search-results-count {
  background: var(--xa-text);
  @include border-radius(0.4rem);
  color: var(--xa-white);
  margin-right: 0.8rem;
  padding: 0 0.4rem;
  white-space: nowrap;

  @include breakpoint('phone-md') {
    display: none;
  }

  .dark & {
    background: var(--xa-black);
  }
}

.xa-search-clear{
  fill: #D3273E50;
  cursor: pointer;
  width: 3.5rem;
  height: 3.5rem;
  margin-right: 5px;

  @include transition(all 0.3s ease);

  &:hover {
    fill: #D3273E;
  }

  .dark & {
    color: var(--xa-text);
  }
}

.xa-search-filter-toggle {
  color: var(--xa-text);
  cursor: pointer;
  font-size: 2rem;
  line-height: 2.8rem;
  min-width: 2rem;
  @include transition(all 0.3s ease-in-out);

  .dark & {
    color: var(--xa-secondary);
  }
}

.xa-search-filter {
  background: var(--xa-white);
  @include border-radius(0.4rem);
  @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.5));
  margin-top: 0.4rem;
  @include opacity(0);
  position: absolute;
  right: 0;
  top: 100%;
  @include transition(all 0.3s ease-in-out);
  visibility: hidden;

  .dark & {
    background: var(--xa-primary);
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.5));
  }

  &.opened {
    @include box-shadow(0 0 1rem 0 var(--xa-text));
    @include opacity(1);
    visibility: visible;
    z-index: 40;

    .dark & {
      background: var(--xa-black);
      @include box-shadow(0 0 1rem 0 var(--xa-text-alt));
    }
  }

  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      cursor: pointer;
      padding: 0.8rem 1.6rem;
      @include transition(all 0.3s ease-in-out);

      &:hover {
        background: rgba(var(--xa-primary-rgb), 0.25);

        .dark & {
          background: rgba(var(--xa-black-rgb), 0.1);
        }
      }

      span {
        color: var(--xa-text);

        .dark & {
          color: var(--xa-white);
        }

        &:hover {
          color: var(--xa-text);

          .dark & {
            color: var(--xa-white);
          }
        }
      }

      &.disabled {
        background: rgba(var(--xa-secondary-rgb), 0.5);

        &:hover {
          cursor: default;
        }

        span {
          color: rgba(var(--xa-text-rgb), 0.5);

          &:hover {
            color: rgba(var(--xa-text-rgb), 0.5);
          }
        }
      }

      &.selected {
        background: var(--xa-primary);

        &:hover {
          background: var(--xa-primary);
        }

        span {
          color: var(--xa-text);

          .dark & {
            color: var(--xa-black);
          }

          &:hover {
            color: var(--xa-text);

            .dark & {
              color: var(--xa-black);
            }
          }
        }
      }
    }
  }
}

.xa-search-filter-title {
  background: rgba(var(--xa-secondary-rgb), 0.25);
  border-bottom: 0.1rem solid var(--xa-secondary);
  @include border-radius(0.4rem 0.4rem 0 0);
  color: var(--xa-text);
  cursor: default;
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0.8rem 1.6rem;
  text-transform: uppercase;

  .dark & {
    background: rgba(var(--xa-primary-rgb), 0.25);
    border-color: rgba(var(--xa-secondary-rgb), 0.15);
    color: var(--xa-white);
  }
}
