/* RC Notification */
.rc-notification {
  .rc-notification-notice {
    background: var(--xa-info);
    border: 0 none;
    @include border-radius(0.4rem);
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.5));
    color: var(--xa-white);

    .dark & {
      background: var(--xa-primary);
      color: var(--xa-text-alt);
    }
  }
}
