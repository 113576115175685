/* Empty - No data */
.xa-no-data {
  position: absolute;
  align-items: center;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  opacity: 0.5;
  padding: 0.8rem;
  

  .image {
    background-image: url(../../assets/images/xa-empty.webp);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 13rem;
    width: 17rem;
  }

  .text {
    color: var(--lp-secondary);
    font-size: 1.4rem;
  }
}
