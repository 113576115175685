/* React Colorful */
.symbology-modal {
  .modal-content {
    .react-colorful {
      align-self: center;
      width: calc(100% - 1.6rem);
    }
  }

  .modal-footer {
    @include flex(flex);
    @include flex-align-items(stretch);
    @include flex-direction(row);
  }
}
