@import 'styles/mixins';

/* Legend */
.xa-legend-container {
  color: var(--xa-text);
  padding: 0.8rem 1.6rem;
}

.selected-title {
  font-weight: 600;
  color: var(--xa-selected);
}

.xa-visualisation-rules-symbology {
  background: rgba(var(--xa-black-rgb), 0.01);
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  @include flex-justify(space-between);
  gap: 0.8rem;
  padding: 0.8rem;
  position: relative;
  width: 100%;

  .dark & {
    background: rgba(var(--xa-white-rgb), 0.05);
  }

  + .xa-visualisation-rules-symbology {
    margin-top: 0.8rem;
  }

  > svg {
    font-size: 3rem;
    margin: 0 0.8rem;
  }
}

.xa-symbology-stepper {
  border: 0.1rem solid var(--xa-secondary);
  @include border-radius(0.4rem);
  @include flex(flex);
  @include flex-direction(column);

  button {
    background: transparent;
    border: 0 none;
    color: var(--xa-text);
    cursor: pointer;
    @include flex(flex);
    font-size: 1.6rem;
    line-height: 1;
    padding: 0.2rem 0.4rem;

    &.xa-symbology-up {
      @include border-radius(0.4rem 0.4rem 0 0);
    }

    &.xa-symbology-down {
      border-top: 0.1rem solid var(--xa-secondary);
      @include border-radius(0 0 0.4rem 0.4rem);
    }
  }
}

.xa-symbol {
  @include flex(flex);
}

.xa-symbology-add,
.xa-symbology-edit,
.xa-symbology-remove {
  background: transparent;
  border: 0.1rem solid var(--xa-secondary);
  @include border-radius(0.4rem);
  cursor: pointer;
  @include flex(flex);
  font-size: 1.6rem;
  line-height: 1;
  padding: 0.4rem;
}

.xa-symbology-add {
  color: var(--xa-success);
}

.xa-symbology-edit {
  color: var(--xa-primary-alt);
}

.xa-symbology-remove {
  color: var(--xa-danger);
}

.xa-symbology-delete {
  background: var(--xa-danger);
  border: 0 none;
  @include border-radius(0 0.4rem);
  color: var(--xa-white);
  cursor: pointer;
  @include flex(flex);
  font-size: 1.6rem;
  line-height: 1;
  padding: 0.2rem 0.4rem;
  position: absolute;
  right: 0;
  top: -1.1rem;
}

.xa-symbology-disabled {
  @include opacity(0.15);
  pointer-events: none;
}

.xa-scale {
  @include flex(flex);
  @include flex-direction(column);
  gap: 0.2rem;
  margin: 1.6rem auto;
  position: relative;
  width: 100%;

  .xa-scale-line {
    background: var(--xa-primary-alt);
    @include flex(flex);
    @include flex-align-items(center);
    @include flex-direction(row);
    @include flex-justify(space-between);
    height: 0.2rem;
    margin-left: 0.3rem;
    width: calc(100% - 0.6rem);

    &:before,
    &:after {
      background: var(--xa-primary-alt);
      content: '';
      @include flex(flex);
      height: 1rem;
      width: 0.2rem;
    }
  }

  .xa-scale-values {
    color: var(--xa-primary-alt);
    @include flex(flex);
    @include flex-align-items(center);
    @include flex-direction(row);
    @include flex-justify(space-between);
    font-size: 1.2rem;
    font-weight: 700;
  }
}

.xa-legend-container {
  .rc-tree {
    .title_render--container {
      @include flex(flex);
      @include flex-align-items(center);
      @include flex-justify(space-between);
      width: 100%;
    }
    
    .favourites_star_img {
      cursor: pointer;
      @include flex(flex);
      @include flex-align-items(center);
      @include flex-justify(center);
    }
    
    .rc-tree-treenode {
      @include flex(flex);
      @include flex-align-items(center);
    }
    
    .rc-tree-node-content-wrapper {
      @include flex-val(1);
      width: 82%;
    }
    
    .rc-tree-title {
      width: 100%;
    
      .title_render--container_title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
}

.favourites_star_img {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rc-tree-treenode {
  display: flex;
  align-items: center;
}

.rc-tree-node-content-wrapper {
  flex: 1;
  overflow: hidden;
}

.rc-tree-title {
  width: 100%;
}

.rc-tree-title .title_render--container_title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotate 1s linear infinite;
}

.delete-confirmation-modal {
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: column;
  height: calc(100% - 3.7rem);
  justify-content: center;
  left: 0;
  position: absolute;
  top: 3.7rem;
  width: 100%;

  .dark & {
    background: rgba(0, 0, 0, 0.85);
  }
}

.delete-confirmation-modal p {
  font-weight: 700;
  margin-top: auto;
  text-align: center;
}

.ReactModal__Content.ReactModal__Content--after-open.react-modal-wrapper {
  position: absolute;
}
