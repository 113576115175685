/*====================
COLORS STYLE
====================*/
@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

/* XA THEME */
/* Light Theme */
:root {
  /* HEX */
  --xa-primary:      #83D0F5;
  --xa-primary-alt:  #DDA14B;
  --xa-secondary:    #D1D1D3;
  --xa-text:         #0B2740;
  --xa-text-alt:     #E8E8E9;
  --xa-white:        #F1F1F1;
  --xa-black:        #181923;
  --xa-info:         #303696;
  --xa-danger:       #D3273E;
  --xa-update:       #EB6724;
  --xa-warning:      #FFC845;
  --xa-success:      #04773B;
  --xa-complementary: #e5e5e5;
  --xa-selected:     #04773B;

  /* RGB */
  --xa-primary-rgb:     #{hexToRGB(#83D0F5)};
  --xa-primary-alt-rgb: #{hexToRGB(#DDA14B)};
  --xa-secondary-rgb:   #{hexToRGB(#D1D1D3)};
  --xa-text-rgb:        #{hexToRGB(#0B2740)};
  --xa-text-alt-rgb:    #{hexToRGB(#E8E8E9)};
  --xa-white-rgb:       #{hexToRGB(#F1F1F1)};
  --xa-black-rgb:       #{hexToRGB(#181923)};
  --xa-info-rgb:        #{hexToRGB(#303696)};
  --xa-danger-rgb:      #{hexToRGB(#D3273E)};
  --xa-update-rgb:      #{hexToRGB(#EB6724)};
  --xa-warning-rgb:     #{hexToRGB(#FFC845)};
  --xa-success-rgb:     #{hexToRGB(#04773B)};
  --xa-complementary-rgb: #{hexToRGB(#e5e5e5)};
  --xa-selected-rgb: #{hexToRGB(#04773B)};
}

/* Dark Theme */
.dark {
  /* HEX */
  --xa-primary:     #83D0F5;
  --xa-primary-alt: #DDA14B;
  --xa-secondary:   #2F3039;
  --xa-text:        #E8E8E9;
  --xa-text-alt:    #0B2740;
  --xa-white:       #F1F1F1;
  --xa-black:       #181923;
  --xa-info:        #989BCB;
  --xa-danger:      #E94560;
  --xa-update:       #EB6724;
  --xa-warning:     #F9CB85;
  --xa-success:     #72B498;
  --xa-complementary: #383848;
  --xa-selected:     #98fb98;

  

  /* RGB */
  --xa-primary-rgb:     #{hexToRGB(#83D0F5)};
  --xa-primary-alt-rgb: #{hexToRGB(#DDA14B)};
  --xa-secondary-rgb:   #{hexToRGB(#2F3039)};
  --xa-text-rgb:        #{hexToRGB(#E8E8E9)};
  --xa-text-alt-rgb:    #{hexToRGB(#0B2740)};
  --xa-white-rgb:       #{hexToRGB(#F1F1F1)};
  --xa-black-rgb:       #{hexToRGB(#181923)};
  --xa-info-rgb:        #{hexToRGB(#989BCB)};
  --xa-danger-rgb:      #{hexToRGB(#E94560)};
  --xa-update-rgb:      #{hexToRGB(#EB6724)};
  --xa-warning-rgb:     #{hexToRGB(#F9CB85)};
  --xa-success-rgb:     #{hexToRGB(#72B498)};
  --xa-complementary-rgb: #{hexToRGB(#383848)};
  --xa-selected-rgb: #{hexToRGB(#98fb98)};
}
  