@import 'styles/mixins';

@function tint($color, $percentage) {
  @return mix(#FFF, $color, $percentage);
}

/* Custom color variables */
$xa-text-tint-10: tint(#0B2740, 10%);
$xa-text-tint-20: tint(#0B2740, 20%);
$xa-black-tint-20: tint(#181923, 20%);

/* Navbar */
.xa-navbar {
  background: var(--xa-text);
  bottom: 0;
  @include box-shadow(0.5rem 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.3));
  color: var(--xa-white);
  @include flex(flex);
  @include flex-direction(column);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  user-select: none;
  z-index: 20;
  transition: width 0.3s ease-in-out;

  .dark & {
    background: var(--xa-black);
    @include box-shadow(0.5rem 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.3));
    color: var(--xa-primary);
  }

  &.collapsed {
    width: 4.8rem;
    transition: width 0.3s ease-in-out;
    @include breakpoint('phone-md') {
      left: -4.8rem;
    }

    .xa-navbar-item-parent-icon,
    .xa-navbar-item-text,
    .xa-navbar-toggle-text {
      white-space: nowrap;
      display: none;
    }
    
    .xa-navbar-item {
      @include flex-direction(row);
      
      > a {
        white-space: nowrap;
        padding: 1.6rem;
      }

      &:hover {
        .xa-navbar-submenu {
          @include opacity(1);
          visibility: visible;
        }
      }
    }

    .xa-navbar-submenu {
      left: 100%;
      @include opacity(0);
      position: absolute;
      top: 0;
      visibility: hidden;
      z-index: 20;

      > a {
        white-space: nowrap;
      }
    }

    .xa-navbar-toggle {
      padding: 1.6rem;

      @include breakpoint('phone-md') {
        left: 100%;
      }
    }
  }

  &.expanded {
    width: 27.5rem;
    transition: width 0.3s ease-in-out;
    &:after {
      background-image: url(../../assets/images/xa-login-road-menu-light.webp);
      background-position: 50% 100%;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 4.8rem;
      content: "";
      display: block;
      height: 40%;
      left: 0;
      @include opacity(0.1);
      position: absolute;
      width: 100%;
      z-index: -1;
    
      .dark & {
        background-image: url(../../assets/images/xa-login-road-menu-dark.webp);
      }
    }

    .xa-navbar-item {
      @include flex-direction(column);
      white-space: nowrap;

      &.submenu-opened {
        .xa-navbar-submenu {
          display: block;
        }
      }
    }

    .xa-navbar-submenu {
      display: none;
      width: 100%;
    }
  }
}

.xa-navbar-item {
  @include flex(flex);
  @include flex-align-items(center);
  position: relative;

  &:hover {
    background: $xa-text-tint-20;

    .dark & {
      background: $xa-black-tint-20;
    }
  }

  > a {
    color: var(--xa-white);
    @include flex(flex);
    @include flex-align-items(center);
    padding: 1.4rem 1.6rem;
    width: 100%;

    &.active {
      background: var(--xa-primary);
      color: var(--xa-text);

      .dark & {
        color: var(--xa-black);
      }
    }
  }
}

.xa-navbar-item-icon {
  @include flex(flex);

  svg {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.xa-navbar-item-text {
  line-height: 2rem;
  margin: 0 1.6rem;
}

.xa-navbar-item-parent-icon {
  @include flex(flex);
  margin-left: auto;
}

.xa-navbar-submenu {
  background: $xa-text-tint-10;
  color: var(--xa-white);

  .dark & {
    background: var(--xa-secondary);
    color: var(--xa-text);
  }

  > a {
    color: var(--xa-white);
    display: block;
    padding: 0.8rem 1.6rem;

    &:hover {
      background: $xa-text-tint-20;

      .dark & {
        background: $xa-black-tint-20;
      }
    }

    &.active {
      background: $xa-text-tint-20;

      .dark & {
        background: var(--xa-black);
      }
    }
  }
}

.xa-navbar-submenu-title {
  background: rgba(var(--xa-black-rgb), 0.5);
  color: var(--xa-secondary);
  display: block;
  font-size: 1.2rem;
  letter-spacing: 0.3rem;
  line-height: 1.6rem;
  padding: 0.4rem 1.6rem;
  text-transform: uppercase;

  .dark & {
    color: var(--xa-text);
  }
}

.xa-navbar-logo {
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  margin-top: auto;
  padding: 0.8rem;

  img {
    height: 4.8rem;
    width: auto;
  }
  &.collapsed img {
    height: 3.2rem;
  }

  .xa-navbar-logo-poweredby {
    color: var(--xa-white);
    font-size: 1rem;
    margin: 0 0.4rem 0 0.8rem;
  }

  > a {
    color: var(--xa-primary-alt);
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}

.xa-navbar-toggle {
  background: var(--xa-text);
  cursor: pointer;
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  height: 4.8rem;
  padding: 1.4rem 1.6rem;
  position: relative;

  .dark & {
    background: var(--xa-black);
  }

  &:hover {
    background: $xa-text-tint-20;

    .dark & {
      background: $xa-black-tint-20;
    }
  }
}

.xa-navbar-toggle-icon {
  @include flex(flex);
}

.xa-navbar-toggle-text {
  line-height: 2rem;
  margin: 0 1.6rem;
}
