@import 'styles/font';
@import 'styles/mixins';

/* Button */
.xa-button {
  border: 0 none;
  @include border-radius(0.4rem);
  cursor: pointer;
  font-family: $xa-font-family;
  font-size: $xa-font-size;
  font-weight: 600;
  line-height: 2rem;
  margin-top: 2.4rem;
  padding: 0.8rem;

  &.primary {
    background: var(--xa-text);
    color: var(--xa-white);

    .dark & {
      background: var(--xa-primary);
      color: var(--xa-black);
    }
  }

  &.secondary {
    background: var(--xa-secondary);
    color: var(--xa-text);
  }

  &.action {
    background: var(--xa-success);
    color: var(--xa-white);

    .dark & {
      color: var(--xa-black);
    }
  }

  &.danger {
    background: var(--xa-danger);
    color: var(--xa-white);
  }

  &.update {
    background: var(--xa-update);
    color: var(--xa-white);
  }

  &.text {
    background: transparent;
    color: var(--xa-text);
  }

  &.full-width {
    width: 100%;
  }

  &.disabled {
    @include opacity(0.25);
    pointer-events: none;
  }

  &.danger {

    background: var(--xa-danger);

    color: var(--xa-white);

  }
}
