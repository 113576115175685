/* React Modal */

.modal-container{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  background: rgba(0,0,0,0.05);
}
.react-modal, .react-modal-asset-hub {
  background: var(--xa-white);
  @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.75));
  @include border-radius(0.4rem);
  display: flex !important;
  @include flex-align-items(flex-start);
  @include flex-direction(column);
  @include flex-justify(flex-start);
  height: auto;
  max-height: calc(100vh - 1.6rem); /* Fallback for browsers which cannot support Custom Properties */
  max-height: calc(var(--vh, 1vh) - 1.6rem);
  max-width: calc(100vw - 11.2rem);
  // max-width: 80vw;
  min-height: 30rem;
  min-width: 30rem;
  position: fixed;
  width: auto;
  z-index: 20;
  user-select: none;

  .dark & {
    background: var(--xa-black);
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.75));
  }

  &.with-asset-hub {
    max-width: 90vw!important;
  }

  &.with-chart {
    min-height: 70rem;

    .modal-header {
      .modal-control {
        display: none;
      }
    }
  }

  &.react-draggable-dragging {
    .modal-header {
      cursor: grabbing;
    }
  }

  &.top-left {
    left: 5.6rem;
    top: 0.8rem;
    transition: left 0.3s .5s ease, right 0.3s .5s ease, top 0.3s ease; /* Smooth transitions */

    @include breakpoint('phone-md') {
      left: 0.8rem;
    }
  }

  &.bottom-left {
    bottom: 0.8rem;
    left: 10rem;

    @include breakpoint('phone-md') {
      left: 0.8rem;
    }
  }

  &.top-right {
    right: 5.6rem;
    top: 0.8rem;

    @include breakpoint('phone-md') {
      right: 0.8rem;
    }
  }

  &.bottom-right {
    bottom: 0.8rem;
    right: 5.6rem;

    @include breakpoint('phone-md') {
      right: 0.8rem;
    }
  }

  .modal-header {
    background: var(--xa-text);
    @include border-radius(0.4rem 0.4rem 0 0);
    color: var(--xa-white);
    @include flex(flex);
    @include flex-align-items(center);
    @include flex-direction(row);
    @include flex-justify(space-between);
    width: 100%;
    user-select: none;

    .dark & {
      background: var(--xa-primary-alt);
      color: var(--xa-black);
    }

    .modal-header-buttons {
      @include flex(flex);
      @include flex-align-items(center);
      @include flex-direction(row);
      @include flex-justify(flex-end);
    }

    .modal-title
    .modal-title-overlay {
      padding: 0.8rem;
      text-transform: uppercase;
      white-space: nowrap;
      width: 100%;
    }

    .symbol-modal-header{
      padding: 0.8rem;
      text-transform: uppercase;
      white-space: nowrap;
      width: 100%;
    }

    .modal-refresh-button {
      cursor: pointer;
    }
    
    .rotating {
      animation: spin 1s linear infinite;
    }

    .modal-control {
      color: var(--xa-white);
      cursor: pointer;
      @include flex(flex);
      font-size: 2.1rem;
      line-height: 1;
      padding: 0.8rem;

      .dark & {
        color: var(--xa-black);
      }
    }

    .modal-close {
      background: rgba(var(--xa-white-rgb), 0.15);
      border-top-right-radius: 0.4rem;
      color: var(--xa-white);
      cursor: pointer;
      @include flex(flex);
      font-size: 2.1rem;
      line-height: 1;
      padding: 0.8rem;

      .dark & {
        background: rgba(var(--xa-black-rgb), 0.15);
        color: var(--xa-black);
      }
    }
  }

  .modal-content {
    @include border-radius(0 0 0.4rem 0.4rem);
    @include flex(flex);
    @include flex-direction(column);
    @include flex-val(1);
    overflow-y: visible;
    overflow-x: scroll;
    padding: 0.8rem;
    width: 100%;

    @-moz-document url-prefix() {
      overflow-y: auto;
    }

    .react-tabs {
      margin: -0.8rem;
    }

    .xa-form-field {
      + .xa-form-field,
      + .xa-visualisation-rules-symbology {
        margin-top: 1.6rem;
      }
    }
    
    textarea.edit-mode{
      background: #fff;
    }
  }

  .modal-footer {
    background: rgba(var(--xa-secondary-rgb), 0.25);
    border-top: 0.1rem solid var(--xa-secondary);
    margin-top: auto;
    padding: 0.8rem;
    text-align: center;
    width: 100%;

    .dark & {
      background: rgba(var(--xa-text-alt-rgb), 0.25);
    }

    &:empty {
      display: none;
    }

    button {
      margin-top: 0;
      white-space: nowrap;
      width: 100%;

      + button {
        margin-left: 0.8rem;
      }
    }
  }

  &.minimized {
    min-height: 0;
    min-width: 0;

    .modal-header {
      background: var(--xa-info);
      @include border-radius(0.4rem);
    }

    .modal-control {
      @include border-radius(0 0.4rem 0.4rem 0);
    }

    .modal-content,
    .modal-close,
    .modal-footer {
      display: none;
    }
  }

  &.resizable {
    position: fixed !important;

    &.minimized {
      height: auto !important;
      width: auto !important;
    }
  }

  &.warning-modal {
    min-height: 0;

    .modal-header {
      background: var(--xa-danger);
      color: var(--xa-white);

      .modal-close {
        .dark & {
          color: var(--xa-white);
        }
      }
    }

    .modal-footer {
      @include flex(flex);
      @include flex-align-items(stretch);
      @include flex-direction(row);
    }
  }
}
