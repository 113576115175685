@import 'styles/mixins';

/* Search Results */
.xa-search-results {
  background: var(--xa-white);
  @include border-radius(0.4rem);
  @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.5));
  left: 0;
  margin-top: 0.4rem;
  max-height: 25.6rem;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 30;

  .dark & {
    background: var(--xa-black);
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.5));
  }
}

.xa-search-results-title {
  background: rgba(var(--xa-secondary-rgb), 0.5);
  font-weight: 700;
  padding: 0.4rem 0.8rem;

  .dark & {
    background: rgba(var(--xa-primary-rgb), 0.25);
  }
}

.xa-search-results-item {
  cursor: pointer;
  padding: 0.2rem 0.4rem;

  &:hover {
    background: rgba(var(--xa-primary-rgb), 0.25);

    .dark & {
      background: var(--xa-secondary);
    }
  }
}
