@import 'styles/mixins';

/* Dashboard */
.xa-map-container {
  height: 100vh; /* Fallback for browsers which cannot support Custom Properties */
  height: var(--vh, 1vh);
  margin-left: 4.8rem;
  overflow: hidden;
  width: calc(100vw - 9.6rem);

  @include breakpoint('phone-md') {
    margin-left: 0;
    width: 100vw;
  }
}
