@import 'styles/mixins';

@function tint($color, $percentage) {
  @return mix(#FFF, $color, $percentage);
}

/* Custom color variables */
$xa-secondary-tint-90: tint(#D1D1D3, 90%);
$xa-text-tint-20:      tint(#0B2740, 20%);
$xa-secondary-tint-25: tint(#2F3039, 25%);
$xa-black-tint-20:     tint(#181923, 20%);

/* Toolbar */
.xa-toolbar {
  background: var(--xa-white);
  bottom: 0;
  @include box-shadow(-0.5rem 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.15));
  color: var(--xa-text);
  @include flex(flex);
  @include flex-direction(column);
  height: 100%;
  overflow-y: overlay;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 20;
  transition: width 0.3s ease-in-out;

  @-moz-document url-prefix() {
    overflow-y: auto;
  }

  .dark & {
    background: $xa-secondary-tint-25;
    @include box-shadow(-0.5rem 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.15));
  }

  &.collapsed {
    width: 4.8rem;

    @include breakpoint('phone-md') {
      right: -4.8rem;
    }

    .xa-toolbar-item-parent-icon,
    .xa-toolbar-item-text,
    .xa-toolbar-toggle-text,
    .xa-toolbar-submenu {
      display: none;
    }

    .xa-toolbar-item {
      @include flex-direction(row);

      > a {
        padding: 1.6rem;
      }
    }

    .xa-toolbar-toggle {
      padding: 1.6rem;

      @include breakpoint('phone-md') {
        right: 100%;
      }
    }
  }

  &.expanded {
    width: 33rem;

    .xa-toolbar-item {
      @include flex-direction(column);

      &.submenu-opened {
        .xa-toolbar-submenu {
          display: block;
        }
      }
    }

    .xa-toolbar-submenu {
      display: none;
      width: 100%;
    }
  }

  @include breakpoint('tablet-landscape') {
    overflow-y: overlay;

    @-moz-document url-prefix() {
      overflow-y: auto;
    }
  }
}

.xa-toolbar-item {
  @include flex(flex);
  @include flex-align-items(center);
  position: relative;

  &:hover,
  &.submenu-opened {
    background: var(--xa-secondary);

    .dark & {
      background: var(--xa-info);
      color: var(--xa-black);
    }

    > a {
      .dark & {
        background: var(--xa-info);
        color: var(--xa-black);
      }
    }
  }

  > a {
    color: var(--xa-text);
    @include flex(flex);
    @include flex-align-items(center);
    padding: 1.4rem 1.6rem;
    width: 100%;

    &.active {
      background: var(--xa-primary-alt);
      color: var(--xa-text);

      .dark & {
        color: var(--xa-black);
      }
    }
  }
}

.xa-toolbar-item-icon {
  @include flex(flex);

  svg {
    font-size: 1.6rem;
    line-height: 1.5;
  }
}

.xa-toolbar-item-text {
  line-height: 2rem;
  margin: 0 1.6rem;
}

.xa-toolbar-item-parent-icon {
  @include flex(flex);
  margin-left: auto;

  svg {}
}

.xa-toolbar-submenu {
  background: $xa-secondary-tint-90;
  color: var(--xa-white);

  .dark & {
    background: var(--xa-secondary);
    color: var(--xa-text);
  }

  > a {
    color: var(--xa-white);
    display: block;
    padding: 0.8rem 1.6rem;

    &:hover {
      background: $xa-text-tint-20;

      .dark & {
        background: $xa-black-tint-20;
      }
    }

    &.active {
      background: var(--xa-primary-alt);

      .dark & {
        background: var(--xa-black);
      }
    }

    span {}
  }
}

.xa-toolbar-toggle {
  background: var(--xa-white);
  bottom: 0;
  cursor: pointer;
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  height: 4.8rem;
  margin-top: auto;
  padding: 1.4rem 1.6rem;
  position: sticky;

  .dark & {
    background: $xa-secondary-tint-25;
  }

  &:hover {
    background: var(--xa-secondary);

    .dark & {
      background: var(--xa-info);
      color: var(--xa-secondary);
    }
  }
}

.xa-toolbar-toggle-icon {
  @include flex(flex);

  svg {}
}

.xa-toolbar-toggle-text {
  line-height: 2rem;
  margin: 0 1.6rem;
}

.rotating {
  animation: spin 1s linear infinite;
}
