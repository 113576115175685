/* RC Slider */
.rc-slider {
  @include border-radius(0.4rem);
  height: 3.6rem;
  margin: auto;
  padding: 0.4rem 0;
  width: calc(100% - 1.6rem);

  .rc-slider-rail {
    background-color: var(--xa-secondary);
    @include border-radius(0.4rem);

    .dark & {
      background-color: rgba(var(--xa-white-rgb), 0.35);
    }
  }

  .rc-slider-track {
    background-color: var(--xa-primary);
    @include border-radius(0.4rem);
  }

  .rc-slider-handle {
    background-color: var(--xa-white);
    border: 0.2rem solid var(--xa-primary);

    .dark & {
      background-color: var(--xa-black);
    }
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: var(--xa-primary);
    @include box-shadow(0 0 0 0.5rem var(--xa-primary));
  }

  .rc-slider-handle:focus-visible {
    border-color: var(--xa-primary);
    @include box-shadow(0 0 0 0.3rem var(--xa-primary));
  }

  .rc-slider-handle-click-focused:focus {
    border-color: var(--xa-primary);
    @include box-shadow(unset);
  }

  .rc-slider-handle:hover {
    border-color: var(--xa-primary);
  }

  .rc-slider-handle:active {
    border-color: var(--xa-primary);
    @include box-shadow(0 0 0.5rem var(--xa-primary));
  }

  .rc-slider-mark {
    top: 1.6rem;
  }

  .rc-slider-mark-text {
    color: var(--xa-text);
  }

  .rc-slider-mark-text-active {
    color: var(--xa-primary);
  }

  .rc-slider-dot {
    background-color: var(--xa-white);
    border: 0.2rem solid var(--xa-secondary);

    .dark & {
      background-color: var(--xa-text);
      border: 0.2rem solid var(--xa-text);
    }
  }

  .rc-slider-dot-active {
    border-color: var(--xa-primary);
  }

  .rc-slider-disabled {
    background-color: var(--xa-secondary);
  }

  .rc-slider-disabled .rc-slider-track {
    background-color: var(--xa-secondary);
  }

  .rc-slider-disabled .rc-slider-handle,
  .rc-slider-disabled .rc-slider-dot {
    background-color: var(--xa-white);
    border-color: var(--xa-secondary);

    .dark & {
      background-color: var(--xa-black);
    }
  }
}
