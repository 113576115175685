@import 'styles/mixins';

/* Attributes */
.xa-attributes-elements {
  margin-bottom: 1.6rem;
  display: flex
}

.xa-attributes-list {
  @include flex(flex);
  @include flex-direction(column);
}

.xa-attributes-list-head {
  background: var(--xa-primary);
  border-bottom: 0.1rem solid var(--xa-secondary);
  @include flex(flex);
  @include flex-align-items(stretch);
  @include flex-direction(row);
  font-weight: 700;
  position: sticky;
  top: -0.8rem;

  .dark & {
    color: var(--xa-black);
  }
}

.xa-attributes-list-row {
  border-bottom: 0.1rem solid var(--xa-secondary);
  @include flex(flex);
  @include flex-align-items(stretch);
  @include flex-direction(row);
}

.xa-attributes-list-label {
  background: rgba(var(--xa-secondary-rgb), 0.25);
  min-width: 13rem;
  padding: 0.8rem;
  width: 15rem;

  &.full-width {
    width: 100%;
  }
}

.xa-attributes-list-value {
  padding: 0.8rem;
  width: 15rem;
}

.xa-attributes-list-value-true {
  color: var(--xa-success);
  font-size: 2rem;
}

.xa-attributes-list-value-unknown {
  font-size: 2rem;
}

.xa-eyevi-title {
  display: block;
  margin-bottom: 0.8rem;
  text-align: left;
  width: 100%;
}

.eyevi-iframe{
  width: 90vw
}

.xa-eyevi-row {
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  @include flex-justify(space-between);
  gap: 1.6rem;

  >div {
    width: 100%;
  }
}

.xa-eyevi-btns {
  margin-top: 1.6rem;
}

.xa-imageHub {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  flex-direction: column;

  .xa-imageHub__main-swiper {
    display: flex;
    position: relative;
    align-items: center
  }

 .xa-imageHub__arrow {
    position: absolute;
    z-index: 99;
    cursor: pointer;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    transition: .3s all;
  }
  .xa-imageHub__arrow:hover{
    transform: scale(.94);
  }

  .xa-imageHub__swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    &.thumb{
      height: 100px;
    }
  }

  .xa-imageHub__swiper{
    width: 480px;
    height: 300px;
  }
  .xa-imageHub-thumbs{
    width: 100%;
    margin-top: 10px;
  }
  .xa-imageHub__asset-name{
    color: #fff;
    font-size: 14;
    font-weight: 600;
  }
}

.asset_hub--container{
  display: flex;
  height: auto;
  // overflow-y: hidden;
}

.xa-asset_hub--info_container{
  margin: 0 10px;
  height: 500px;
  overflow-y: scroll;
  border: 1px solid #4d4d4d;
  padding: 4px;
  border-radius: 4px;
}

.xa_asset_hub--legend_container{
  min-width: 25rem;
  border: 1px solid #4d4d4d;
  padding: 4px;
  border-radius: 4px;
  overflow-y: scroll;
}

.empty_request--container{
  min-width: 300px;
}

.xa_asset_hub--options_container{
  border: 1px solid #4d4d4d;
  padding: 4px;
  border-radius: 4px;
  width: auto;
}

/* Charts */
.xa-chart_asset {
  position: relative;
  height: 39rem;
  margin-top: 1.6rem;
  min-width: 50rem;
  width: 100%;
}

.xa-chart_asset-footer {
  background: rgba(var(--xa-secondary-rgb), 0.25);
  border-top: 0.1rem solid var(--xa-secondary);
  margin-top: auto;
  margin-left: -0.4rem;
  padding: 0.8rem;
  text-align: center;
  width: calc(100% + 0.8rem);

  .dark & {
    background: rgba(var(--xa-text-alt-rgb), 0.25);
  }

  &:empty {
    display: none;
  }

  button {
    margin-top: 0;
    white-space: nowrap;
    width: 100%;

    + button {
      margin-left: 0.8rem;
    }
  }
}

.xa_asset_hub--options-select {
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-direction(row);
  @include flex-justify(space-between);
  gap: 0.8rem;

  .custom-switch {
    @include border-radius(0.4rem);
    white-space: nowrap;

    label {
      @include border-radius(0.4rem);
    }
  }
}

.modal-header {
  padding-left: 8px;
}

.modal-title-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modal-title-in-container{
  text-transform: capitalize;
}
.modal-refresh-button {
  cursor: pointer;
}

.rotating {
  animation: spin 1s linear infinite;
}

.modal-title-container-asset-hub {
  display: flex;
  align-items: center;
  gap: 0;
}
