@import 'styles/font';
@import 'styles/mixins';

/* User Container */
.xa-user-container {
  bottom: 0.8rem;
  left: 5.6rem;
  position: fixed;
  z-index: 15;

  ul {
    background: var(--xa-white);
    @include border-radius(0.4rem);
    bottom: calc(100% + 0.8rem);
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.75));
    list-style: none;
    margin: 0;
    @include opacity(0);
    padding: 0;
    position: absolute;
    @include transition(all 0.3s ease-in-out);
    visibility: hidden;

    .dark & {
      background: var(--xa-black);
      @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.75));
    }

    li {
      padding: 0.8rem 1.6rem;
    }
  }

  &.user-opened {
    ul {
      @include opacity(1);
      visibility: visible;
    }
  }
}

.xa-user-avatar {
  background: var(--xa-info);
  @include border-radius(50%);
  @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.5));
  color: var(--xa-white);
  cursor: pointer;
  @include flex(flex);
  @include flex-align-items(center);
  @include flex-justify(center);
  font-weight: 900;
  height: 3.6rem;
  @include transition(all 0.3s ease-in-out);
  width: 3.6rem;

  .dark & {
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.5));
    color: var(--xa-black);
  }

  &:hover {
    @include box-shadow(0 0 1rem 0 var(--xa-text));

    .dark & {
      @include box-shadow(0 0 1rem 0 var(--xa-text-alt));
    }
  }
}

.xa-user-info {
  background: rgba(var(--xa-secondary-rgb), 0.15);
  border-bottom: 0.1rem solid var(--xa-secondary);
  @include border-radius(0.4rem 0.4rem 0 0);
  @include flex(flex);
  @include flex-direction(column);
  padding: 0.8rem 1.6rem;
  @include transition(all 0.3s ease-in-out);

  .dark & {
    background: rgba(var(--xa-secondary-rgb), 0.5);
  }
}

.xa-user-tenant {
  border-bottom: 0.1rem solid var(--xa-secondary);
}

.xa-user-name {
  color: var(--xa-text);
  font-size: $xa-font-size;
  font-weight: 700;
  line-height: 2rem;
}

.xa-user-email {
  color: rgba(var(--xa-text-rgb), 0.5);
}

.xa-user-theme {
  color: var(--xa-text);
  cursor: pointer;
  @include flex(flex);
  @include flex-align-items(center);

  &:hover {
    color: var(--xa-text);
  }

  img {
    height: 2.4rem;
    margin-left: 0.8rem;
    width: auto;
  }
}

.xa-user-logout {
  background: rgba(var(--xa-danger-rgb), 0.15);
  @include border-radius(0 0 0.4rem 0.4rem);
  color: var(--xa-danger);
  cursor: pointer;
  @include flex(flex);
  @include flex-align-items(center);
  padding: 0.8rem 1.6rem;
  @include transition(all 0.3s ease-in-out);

  &:hover {
    background: rgba(var(--xa-danger-rgb), 0.25);
  }

  .dark & {
    background: var(--xa-danger);
    color: var(--xa-text);

    &:hover {
      background: var(--xa-danger);
    }
  }

  svg {
    margin-right: 0.8rem;
  }
}
