/* React Select */
.custom_select {
  @include box-sizing(border-box);
  position: relative;

  span[aria-live='polite'] {
    border: 0;
    -webkit-clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
    clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
    height: 0.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 0.1rem;
    z-index: 9999;
  }

  .custom_select__control {
    background-color: var(--xa-white);
    border: 0.1rem solid var(--xa-secondary);
    @include border-radius(0.4rem);
    @include box-sizing(border-box);
    cursor: pointer;
    @include flex(flex);
    @include flex-align-items(center);
    @include flex-justify(space-between);
    @include flex-wrap(wrap);
    height: auto;
    min-height: 3rem;
    outline: 0 !important;
    position: relative;
    @include transition(none);

    .dark & {
      background-color: var(--xa-secondary);
    }

    &:hover,
    &.custom_select__control--is-focused {
      border-color: var(--xa-primary);
      @include box-shadow(none);
    }

    .custom_select__value-container {
      @include box-sizing(border-box);
      @include flex(flex);
      @include flex-align-items(center);
      @include flex-wrap(wrap);
      -webkit-overflow-scrolling: touch;
      overflow: hidden;
      padding: 0 0.4rem;
      position: relative;

      .custom_select__placeholder,
      .custom_select__single-value {
        color: var(--xa-text);
        font-family: $xa-font-family;
        font-size: $xa-font-size;
        line-height: 1;
        margin-left: 0;
        margin-right: 0;
        max-width: 100%;

        + div[class^='css-'] {
          margin: 0 !important;
          padding: 0 !important;
        }
      }

      .custom_select__single-value {
        @include box-sizing(border-box);
        overflow: hidden;
        position: absolute;
        text-overflow: ellipsis;
        top: 50%;
        @include translate(0, -50%);
        white-space: nowrap;

        + div {
          @include box-sizing(border-box);
          color: var(--xa-text);
          visibility: visible;
        }
      }

      > div[class$='-Input'] {
        margin: 0;
        padding: 0;
      }

      .custom_select__input {
        input {
          background: transparent;
          border: 0 none;
          @include border-radius(0);
          @include box-shadow(none);
          @include box-sizing(border-box);
          color: var(--xa-text) !important;
          font-size: 1.2rem;
          height: auto;
          @include opacity(1);
          padding: 0;
          @include transition(none);
          width: 2rem;
        }
      }
    }

    .custom_select__indicators {
      align-self: stretch;
      @include box-sizing(border-box);
      @include flex(flex);
      @include flex-align-items(center);
      padding-right: 0.4rem;

      .custom_select__indicator-separator {
        display: none;
      }

      .custom_select__indicator {
        @include box-sizing(border-box);
        color: var(--xa-secondary);
        @include flex(flex);
        padding: 0;
        @include transition(none);

        .dark & {
          color: var(--xa-text);
        }

        svg {
          display: inline-block;
          fill: currentColor;
          height: 2rem;
          line-height: 1;
          stroke: currentColor;
          stroke-width: 0;
          width: 2rem;
        }

        &.custom_select__clear-indicator {
          &:hover {
            svg {
              @include svg(var(--xa-danger));
            }
          }
        }

        &.custom_select__dropdown-indicator {
          &:hover {
            svg {
              @include svg(var(--xa-primary));
            }
          }
        }
      }

      .custom_select__loading-indicator {
        > span {
          background-color: var(--xa-primary);
          @include border-radius(50%);
          height: 0.8rem;
          margin-left: 0.4rem;
          width: 0.8rem;
        }
      }
    }
  }

  &.custom_select--is-disabled {
    .custom_select__control {
      @include opacity(0.5);
    }
  }

  &.multiple-choices {
    .custom_select__control {
      height: auto;
      min-height: 3rem;
    }
  }

  .custom_select__menu {
    @include box-sizing(border-box);
    background-color: var(--xa-white);
    @include border-radius(0.4rem);
    @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-rgb), 0.75));
    margin: 0;
    position: absolute;
    width: 100%;
    z-index: 15;

    .dark & {
      background-color: var(--xa-secondary);
      @include box-shadow(0 0 0.5rem 0 rgba(var(--xa-text-alt-rgb), 0.75));
    }

    .custom_select__menu-list {
      @include box-sizing(border-box);
      max-height: 8.9rem;
      overflow-y: auto;
      padding-bottom: 0;
      padding-top: 0;
      position: relative;
      -webkit-overflow-scrolling: touch;

      .custom_select__option {
        background-color: transparent;
        @include box-sizing(border-box);
        color: var(--xa-text);
        cursor: pointer;
        display: block;
        font-family: $xa-font-family;
        font-size: $xa-font-size;
        line-height: $xa-line-height;
        padding: 0.4rem 0.8rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        width: 100%;

        &.custom_select__option--is-selected {
          background-color: var(--xa-primary);
          color: var(--xa-text);
          cursor: pointer;
          font-family: $xa-font-family;

          .dark & {
            color: var(--xa-black);

            &:hover {
              background-color: var(--xa-primary);
              color: var(--xa-black);
            }
          }
        }
      }
    }
  }

  &.invalid {
    .custom_select__control {
      background-color: rgba(var(--xa-danger-rgb), 0.15);
      border-color: var(--xa-danger);

      &:hover,
      &.custom_select__control--is-focused {
        border-color: var(--xa-danger);
      }

      .custom_select__indicators {
        .custom_select__indicator {
          color: var(--xa-danger);

          &.custom_select__dropdown-indicator {
            &:hover {
              svg {
                @include svg(var(--xa-danger));
              }
            }
          }
        }
      }
    }
  }
}
